<template>
  <section v-if="isGetRequestPending">
    <div class="loading">
      <hub-icon name="loading" spin size="xxlg"></hub-icon>
    </div>
  </section>
  <section v-else-if="!collection.length">
    <div class="status-message">No docketing tasks were received for this invention</div>
  </section>
  <section v-else style="overflow-y: scroll">
    <div ref="streamRootRef" class="emails-stream">
      <ul class="emails-list">
        <li v-for="item of collection" :key="item.id" class="emails-list-item">
          <hub-message v-bind="item" />
        </li>
        <hub-observe v-if="total > collection.length && !isMoreRequestPending" :get-root-ref="() => $refs['streamRootRef']" @intersect="more">
          <hub-button variant="text" @click="more">more<hub-icon name="refresh"></hub-icon></hub-button>
        </hub-observe>
        <div v-if="isMoreRequestPending" style="text-align: center"><hub-icon name="loading" spin size="lg"></hub-icon></div>
      </ul>
    </div>
  </section>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

import Icon from '../../common/Icon.vue';
import Message from '../Message.vue';
import Observer from '../Observer.vue';
import Button from '../../common/Button.vue';

export default {
  components: {
    'hub-icon': Icon,
    'hub-button': Button,
    'hub-message': Message,
    'hub-observe': Observer
  },
  data() {
    return {
      to: null
    };
  },
  computed: {
    ...mapGetters({
      collection: 'docketing/collection'
    }),
    ...mapState({
      isGetRequestPending: s => s.docketing.isRequestPending,
      isMoreRequestPending: s => s.docketing.isMoreRequestPending,
      total: s => s.docketing.total
    })
  },
  watch: {
    'collection.length'() {
      if (!this.$refs.streamRootRef) {
        return;
      }
      const list = this.$refs.streamRootRef.querySelector('ul');
      const scrollHeight = list.scrollTop;
      if (scrollHeight > -100 && scrollHeight < 100) {
        this.$nextTick(() => {
          list.scrollTop = 0;
        });
      }
    }
  },
  async unmounted() {
    await this.$store.dispatch('docketing/disconnect');
    this.$store.dispatch('docketing/unsubscribe', { inventionId: this.$route.params.id });
  },
  async created() {
    await this.$store.dispatch('docketing/connect');

    this.$store.dispatch('docketing/subscribe', { inventionId: this.$route.params.id });

    try {
      this.to = new Date();

      await this.$store.dispatch('docketing/load', { id: this.$route.params.id });
    } finally {
      this.isReady = true;
    }
  },
  methods: {
    async more() {
      await this.$store.dispatch('docketing/load', { id: this.$route.params.id, skip: this.collection.length, to: this.to });
    }
  }
};
</script>

<style lang="scss" scoped>
.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}

.status-message {
  text-align: center;
  font-style: italic;
  justify-self: center;
  align-self: center;
}

.emails-stream.emails-stream {
  height: 100%;
  display: flex;
  overflow-y: scroll;
  flex-direction: column;
  padding-bottom: 15px;

  grid-template-rows: 1fr 50px;
  .emails-list {
    display: flex;
    flex-direction: column-reverse;
    overflow-y: scroll;
    flex: 1;
    .emails-list-item {
      margin-bottom: 0.25rem;
    }
  }
  > footer {
    height: 50px;
    padding: 0 1.25rem;
  }
}
</style>
