<template>
  <div class="load-more">
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    getRootRef: {
      default: () => null,
      type: Function
    }
  },
  emits: ['intersect'],
  data() {
    return {
      observer: null
    };
  },
  created() {},
  mounted() {
    const options = {
      root: this.getRootRef(),
      rootMargin: '150px',
      threshold: 0.25
    };
    this.observer = new IntersectionObserver(entries => {
      const firstEntry = entries[0];
      if (firstEntry.isIntersecting) {
        this.$emit('intersect');
      }
    }, options);

    setTimeout(() => this.observer.observe(this.$el), 100);
  },
  unmounted() {
    this.observer.disconnect();
  }
};
</script>

<style scoped lang="scss">
.load-more {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
  height: 50px;
  width: 100%;
}
</style>
