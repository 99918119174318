<template>
  <component
    :is="component"
    v-bind="{ title, message, type, createdBy: createdBy || metadata?.task?.createdBy, createdAt, inventionId, metadata }"
    @click="$event => $emit('click', $event)"
    @showForm="$event => $emit('showForm')"
    @close="$event => $emit('close', $event)"
  />
</template>

<script>
import { defineAsyncComponent } from 'vue';

export default {
  components: {},
  props: {
    title: {
      type: String,
      default: ''
    },
    message: {
      type: String,
      required: true
    },
    type: {
      type: String,
      required: true
    },
    createdBy: {
      type: String,
      required: true
    },
    createdAt: {
      type: [Date, String],
      required: true
    },
    inventionId: {
      type: String,
      required: true
    },
    metadata: {
      type: Object,
      default: () => null
    }
  },
  emits: ['click', 'close', 'showForm'],
  computed: {
    component() {
      switch (this.type) {
        case 'task:created':
          return defineAsyncComponent(() => import('./widgets/TaskCreated'));
        case 'task:updated':
        case 'task:migrated':
          return defineAsyncComponent(() => import('./widgets/TaskUpdated'));
        case 'correspondence:created':
          return defineAsyncComponent(() => import('./widgets/CorrespondenceCreated'));
        case 'docketing-task:created':
          return defineAsyncComponent(() => import('./widgets/DocketingTaskCreated'));
        case 'docketing-task:updated':
          return defineAsyncComponent(() => import('./widgets/DocketingTaskUpdated'));
        case 'docketing-task:deleted':
          return defineAsyncComponent(() => import('./widgets/DocketingTaskDeleted'));
        case 'uspto:file.downloaded':
          return defineAsyncComponent(() => import('./widgets/UsptoFileDownloaded'));
        case 'milestone:created':
        case 'milestone:updated':
        case 'milestone:deleted':
        case 'milestone:finished':
        case 'milestone:migrated':
          return defineAsyncComponent(() => import('./widgets/Milestone'));
        default:
          return defineAsyncComponent(() => import('./widgets/Message'));
      }
    }
  }
};
</script>
